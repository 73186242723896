import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";

import L from "leaflet";
import "leaflet-defaulticon-compatibility";

$(document).ready(function() {
  $(".map").each(function() {
    let data = $(this).data();
    let map = L.map(this, {
      center: [data.lat, data.lng],
      zoom: 13,
      tap: false,
      scrollWheelZoom: false
    });
    L.tileLayer("https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      maxZoom: 18
    }).addTo(map);
    let marker = L.marker([data.lat, data.lng]).addTo(map);
    map.on("popupopen", function(e) {
      let px = map.project(e.popup._latlng);
      px.y -= e.popup._container.clientHeight / 2;
      map.panTo(map.unproject(px), { animate: true });
    });
    marker
      .bindPopup(
        `
      <div class="h4">${data.title}</div>
      <dl>
        ${
          data.phone
            ? `
          <dt>Phone</dt>
          <dd>${data.phone}</dd>
        `
            : ""
        }
        ${
          data.email
            ? `
          <dt>Email</dt>
          <dd>${data.email}</dd>
        `
            : ""
        }
      </dl>
    `
      )
      .openPopup();
  });
});
