$(document).ready(function() {
  $(".rich-text a").each(function() {
    if (
      $(this)
        .attr("href")
        .substring(0, 4) == "http"
    ) {
      $(this).attr("target", "_blank");
      $(this).attr("rel", "noopener noreferrer");
    }
  });
});
